<template>
  <div class="resolutionReview">
    <!-- tab切换 -->
    <base-tabs type="border-card" v-model="activeNames"  @click="hander">
      <base-tab-pane label="待审核" name="1"></base-tab-pane>
      <base-tab-pane label="审核通过" name="2"></base-tab-pane>
      <base-tab-pane label="返回修改" name="3"></base-tab-pane>
      <resolution-review-main :activeNames="activeNames" :gysData="gysData"></resolution-review-main>
    </base-tabs>
  </div>
</template>
<script>
import { dataForm, dataTable } from './utils/config'
import BaseTabs from '@/components/common/tabs/base-tabs/base-tabs.vue'
import BaseTabPane from '@/components/common/tabs/base-tab-pane/base-tab-pane.vue'
import resolutionReviewMain from './resolution-review-main.vue'
import { supplierApi } from '@/api/companyApi'
export default {
  name: 'resolutionReview',
  components: { BaseTabs, BaseTabPane, resolutionReviewMain },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      activeNames: '1',
      gysData: []
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    }
  },
  created () {
    if (sessionStorage.getItem('activeNames')) {
      this.activeNames = sessionStorage.getItem('activeNames')
    }
  },
  methods: {
    hander () {
      sessionStorage.setItem('activeNames', this.activeNames)
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-tabs--border-card{
  box-shadow: none;
  border: 0;
  margin-top:10px ;
}
/deep/ .el-tabs__content{
  padding: 0 !important;
}
.resolutionReview {
  height: 100%;
}
</style>
